import config from "config";
import { UserContext } from "context/userContext";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createVerificationSession } from "services/api";
import BackDl from "./backDl";
import EnrollProve from "./enrollProve";
import FrontDl from "./frontDl";
import IntroScreen from "./introScreen";
import UserConsent from "./userConsent";
import UserIdentity from "./userIdentity";

let loaded = false;
const ProveRegister = () => {
  const { configuration, setTokenParams }: any = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [showCamera, setShowCamera] = useState(false);
  const [searchParams] = useSearchParams();
//   useEffect(() => {
//     setTimeout(() => {
//       setShowCamera(true);
//     }, 5000);
//   }, []);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className={`absolute z-[9999999999] bg-[#0c121ca1] h-[100vh]`}>
            <UserIdentity setStep={setStep} />
          </div>
        );
      case 2:
        return <EnrollProve setStep={setStep} isEnroll={true} />;
      case 3:
        return (<div className="frontDlAnimation"><FrontDl setStep={setStep} /></div>);
      case 4:
        return (<div className="backDlAnimation"><BackDl setStep={setStep} /></div>);
        case 5:
        return (<IntroScreen setStep={setStep} />);
      default:
        break;
    }
  };

  const onRegister = async () => {
    const payload: any = {
      ...config?.clientConfig,
      productGroupId:
        configuration?.productGroupId ||
        config?.clientConfig?.productGroupId ||
        "priflo",
    };
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const result: any = await createVerificationSession(payload);
    setTokenParams(result?.token?.replace("?", ""));
    const params = `token=${result?.token?.replace("?", "")}${
      searchParams.get("skipAntispoof")
        ? `&skipAntispoof=${searchParams.get("skipAntispoof")}`
        : ""
    }`;
    url.search = params.toString();
    window.history.pushState({}, "", url);
  };
  useEffect(() => {
    if (!loaded) {
      loaded = true;
      onRegister();
    }
  }, [loaded]);
  return (
    <div className="bg-[#0c121c] backdrop-blur-md max-md:h-[calc(100vh_-80px)] relative overflow-hidden">
      <div className="max-md:w-[100%] w-[35%] m-auto h-[100vh] relative mb-[0px] max-md:h-[calc(100vh_-80px)]">
        {/* <UserConsent /> */}
        {renderStep()}
        {/* {showCamera && step === 1 && (
          <EnrollProve setStep={setStep} isEnroll={false} />
        )} */}
      </div>
    </div>
  );
};

export default ProveRegister;

import { useContext, useEffect, useState } from "react";
import useWasm from "hooks/useWasm";
import { getOrganizationFromURL, isMobile } from "utils";
import { CameraConfig, ENROLL_CANVAS_RESOLUTION } from "constant";
import {
  documentImageTypeEnum,
  uploadDocumentImageWithSession,
} from "@privateid/cryptonets-web-sdk";
import useCamera from "hooks/useCamera";
import useCameraPermissions from "hooks/useCameraPermissions";
import ProveLogo from "assets/proveLogo.png";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import FaceAnimation from "common/animateCircle/faceScanAnimation";
import { getBackDocumentStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import Lottie from "lottie-react";
import completedCheck from "Animations/4-Capture-successfully/JSON/confetti.json";
import backDlImage from "assets/backDl.svg";
import useScanBackDocument from "hooks/useScanBackDocument";

const rendererSettings = {
  preserveAspectRatio: "xMaxYMin slice",
};

const BackDl = ({ setStep }: { setStep: (e: number) => void }) => {
  const context: any = useContext(UserContext);
  const [deviceId, setDeviceId] = useState<string>();
  const [phone, setPhone] = useState<string>("");
  const [showPhone, setShowPhone] = useState(false);
  const { isCameraGranted }: any = useCameraPermissions(() => {});
  const { navigateToNextPage } = useNavigation();
  const [minimizeCamera, setMinimizeCamera] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isCompletedPredict, setIsCompletedPredict] = useState(false);
  const [overlay, setOverlay] = useState(true);

  // Camera and Wasm init
  const onCameraFail = () => {
    //   cameraFail?.();
  };
  const cameraReady = () => {
    //   onCameraReady?.();
    onCameraReady();
  };
  const documentScan = false;
  const { ready: wasmReady, wasmStatus } = useWasm();
  const canvasResolution =
    !isMobile && !documentScan ? ENROLL_CANVAS_RESOLUTION : null;
  const { ready, init, device, devices } = useCamera(
    CameraConfig?.elementId,
    documentScan ? "back" : (CameraConfig?.mode as any),
    CameraConfig?.requireHD,
    onCameraFail,
    documentScan,
    canvasResolution
  );
  useEffect(() => {
    if (device) {
      setDeviceId(device);
    }
  }, [device]);
  const handleWasmLoad = () => {
    if (!wasmReady && wasmStatus.isChecking) return;
    if (wasmReady && !wasmStatus.isChecking && wasmStatus.support) {
      if (!ready) {
        init();
      } else if (isCameraGranted && ready) {
        cameraReady();
      }
    }
    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onCameraFail();
    }
  };
  useEffect(() => {
    handleWasmLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasmReady, ready, wasmStatus]);
  const onStatus = () => {};
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/predict-fail", "/predict-scan");
    }
  };

  useEffect(() => {
    if (isCompletedPredict && !showPhone) {
      setCompleted(true);
      onDocumentSuccess();
    }
  }, [isCompletedPredict, showPhone]);

  useEffect(() => {
    if (phone?.length >= 12) {
      setShowPhone(false);
    }
  }, [phone]);

  const handleAnimationComplete = (state: string) => {
    if (state === "start") {
      // setStartAnimation(true);
    } else if (state === "completed") {
      setCompleted(true);
      onDocumentSuccess();
    }
  };

  useEffect(() => {
    if (completed) {
      setTimeout(() => {
        navigateToNextPage("", "/waiting", "/prove-register");
      }, 3000);
    }
  }, [completed]);

  useEffect(() => {
    setTimeout(() => {
      setOverlay(false);
    }, 3000);
  }, []);

  const onSuccess = async (result: any) => {
    const { barcodeData, croppedDocument, croppedBarcode } = result;
    console.log(result, "result129");

    setCompleted(true);
    setIsCompletedPredict(true);
    context.setUser({ ...context.user, barcodeData });
    async function uploadDocumentAndUpdateImages() {
      const promises = [];
      if (croppedDocument) {
        promises.push(
          uploadDocumentImageWithSession({
            sessionToken: context?.tokenParams,
            documentImageType: documentImageTypeEnum.BACK_CROPPED_DOCUMENT,
            documentId: context?.user?.documentId,
            imageString: croppedDocument,
          })
        );
      }
      if (croppedBarcode) {
        promises.push(
          uploadDocumentImageWithSession({
            sessionToken: context?.tokenParams,
            documentImageType: documentImageTypeEnum.BACK_BARCODE,
            documentId: context?.user?.documentId,
            imageString: croppedBarcode,
          })
        );
      }

      await Promise.all(promises);
    }
    if (context?.configuration?.uploadToServer) {
      await uploadDocumentAndUpdateImages()
        .then(() => {
          console.log("All uploads and updates completed successfully.");
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
    const backDocumentData = {
      firstName: barcodeData?.barcode_data?.firstName,
      lastName: barcodeData?.barcode_data?.lastName,
      dob: barcodeData?.barcode_data?.dateOfBirth,
      address: {
        addressLine1: barcodeData?.barcode_data?.streetAddress1,
        addressLine2: barcodeData?.barcode_data?.streetAddress2,
        city: barcodeData?.barcode_data?.city,
        state: barcodeData?.barcode_data?.state,
        zipCode: barcodeData?.barcode_data?.postCode,
        country: barcodeData?.barcode_data?.issuingCountry,
        idDocumentNumber: barcodeData?.barcode_data?.customerId,
      },
    };

    context.setUser({ ...context.user, backDocumentData });
  };
  const onDocumentSuccess = () => {
    setTimeout(() => {
      navigateToNextPage("", "/waiting", "/prove-register");
    }, 2000);
  };

  const { scanBackDocument, barcodeStatusCode } = useScanBackDocument(
    onSuccess
  ) as any;
  const onCameraReady = () => {
    scanBackDocument();
  };

  const status =
    barcodeStatusCode === 0
      ? "Success"
      : getBackDocumentStatusMessage(barcodeStatusCode);

  return (
    <>
      <div className="bg-[#000] w-[100%] absolute top-[0px] left-[0px] max-md:w-[100%] h-[100vh] max-md:top-[0px] max-md:h-[calc(100vh_-80px)] flex flex-col items-center justify-center">
        <img
          src={ProveLogo}
          alt="ProveLogo"
          className="absolute top-[10px] z-[99999] max-md:right-[40%] right-[unset] left-[40%] max-md:left-[unset]"
        />

        {completed ? (
          <div className="absolute h-full w-full top-0 bg-[#0c121c] flex items-center justify-center">
            <Lottie
              loop={false}
              autoplay={true}
              animationData={completedCheck}
              style={{
                height: isMobile ? 320 : "260px",
              }}
              rendererSettings={isMobile ? {} : rendererSettings}
            />
          </div>
        ) : (
          <>
            <FaceAnimation
              isCircle={minimizeCamera}
              isScanned={completed}
              handleAnimationComplete={handleAnimationComplete}
              fullScreen={true}
            >
              {overlay && (
                <div className="w-full h-full flex z-[999999999] items-center justify-center absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                  <img
                    src={backDlImage}
                    alt="dl_image"
                    className="w-[70%] filter invert-[100%]"
                  />
                </div>
              )}
              <video
                id="userVideo"
                muted
                autoPlay
                playsInline
                className={`w-full h-full object-cover`}
              />
            </FaceAnimation>
              <div className="text-[20px] absolute bottom-28 w-full bg-black bg-opacity-70 text-white text-center py-2 rounded-lg">
                {status || "BACK OF DRIVER'S LICENSE"}
              </div>
          </>
        )}
      </div>
    </>
  );
};

export default BackDl;

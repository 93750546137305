import loginFrame from "../../assets/login/login-main-frame.svg";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { UserContext } from "context/userContext";
import { useContext, useState } from "react";
import { createUserWithSession } from "@privateid/cryptonets-web-sdk";


type Props = {
  setStep: (e: number) => void;
};

const UserIdentity = (props: Props) => {
  const { setStep } = props;
  const context: any = useContext(UserContext);
  const [disable, setDisable] = useState<boolean>(false);
  const onRegister = async () => {
    const payload = {
      sessionToken: context.tokenParams,
      phone: "9999999999",
      ssn: "",
    };
    context.setUser({ ...context.user });
    setDisable(false);
    const userResult = await createUserWithSession(payload);
    setDisable(true);
    if (userResult?.success) {
      setStep(5);
    }
    
  };
  return (
    <div className="pt-[60px] bg-[#595959] h-full">
      <Label className="text-[25px] font-[Google Sans] font-[500] text-[#fff] block text-center">
        Take a selfie to register
      </Label>
      <Label className="text-[15px] font-[Google Sans] font-[400] text-[#fff] block text-center">
        No images will leave your device.
      </Label>
      {/* <div className="flex justify-center mt-0 h-[260px]">
        <img src={loginFrame} alt="" width={500} />
      </div> */}
      <div className="flex flex-col w-[80%] m-auto max-md:w-[100%] max-md:p-[20px]">
        <h5 className="text-[20px] text-[#fff] mt-[50px] font-[600]">USER CONSENT</h5>
        <Label className="mt-[0px] text-[15px] font-[Google Sans] font-[100] text-white">
          By clicking the 'Agree and continue' button below, you acknowledge
          that you are over eighteen (18) years of age, have read the Prove LLC
          and Private Identity LLC{" "}
          <a href="/" className="underline">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="/" className="underline">
            Terms of Use
          </a>{" "}
          and understand how your personal data will be processed in connection
          with your use of this Identity Verification Service.
        </Label>
        <Label className="mt-[10px] text-[15px] font-[Google Sans] font-[100] text-white">
          <a href="/" className="underline">
            Learn
          </a>{" "}
          how identity verification works.
        </Label>
      </div>
      <div className="mt-[50px] flex justify-center max-md:mt-16">
        <Button
          className="w-[65%] bg-[#777] rounded-[24px] text-[14px] hover:opacity-90 hover:bg-[#777] border-2 border-[#fff] text-white"
          onClick={() => onRegister()}
          disabled={disable}
        >
          Agree and continue
        </Button>
      </div>
      <div className="mt-4 flex justify-center max-md:mt-2">
        <Button className="w-[65%] bg-[#777] rounded-[24px] text-[14px] hover:opacity-90 hover:bg-[#777] border-2 border-[#fff] text-white">
          Back to options
        </Button>
      </div>
    </div>
  );
};

export default UserIdentity;
